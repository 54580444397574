import React from 'react'
import classnames from 'classnames'
import twemoji from 'twemoji'
import styled from 'styled-components'

const Container = styled.span`
  display: inline-block;

  > img.emoji {
    height: 1em;
    width: 1em;
    margin: 0 0.05em 0 0.1em;
    vertical-align: -0.1em;
  }

  ${({ isFlag }) =>
    isFlag &&
    `
      > img.emoji {
        height: 1.2em;
        width: 1.2em;
        margin: 0 .05em 0 .1em;
        vertical-align: -0.25em;
      }
  `}
`

export const EmojiList = {
  graduationCap: '🎓',
  blueBook: '📘',
  greenBook: '📗',
  brain: '🧠',
  robot: '🤖',
  books: '📚',
  partyPopper: '🎉',
  teacher: '🧑‍🏫',
  glowingStar: '🌟',
  wrappedGift: '🎁',
  scroll: '📜',
  directHit: '🎯',
  wavingHand: '👋',
  timerClock: '⏲️',
  rocket: '🚀',
  heart: '❤️',
  testTube: '\uD83E\uDDEA',
  mechanicalArm: '\uD83E\uDDBE',
  flags: {
    uk: '🇬🇧',
  },
}

type EmojiProps = {
  emoji: string
  isFlag: boolean
  classNames: any
}

const Emoji = React.memo(({ emoji, isFlag = false, classNames }: EmojiProps) => (
  <Container
    isFlag={isFlag}
    className={classnames(classNames)}
    dangerouslySetInnerHTML={{ __html: twemoji.parse(emoji) }}
  />
))

Emoji.displayName = 'Emoji'

export default Emoji
